module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing-3/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Squirrel Van Life","short_name":"Squirrel","start_url":"/","background_color":"#fff","theme_color":"#784899","display":"minimal-ui","icon":"src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"46a20431d5b6038c63101a15757ab997"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
